import React from 'react';
import { ReactComponent as SalesImage } from '../../assets/SALES.svg';
import styled from 'styled-components';

const SalesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
  background-color: #0a0a15;
  
  @media (max-width: 768px) {
    padding: 0.5rem;
    align-items: flex-start;
    padding-top: 2rem;
  }
`;

const StyledSalesImage = styled(SalesImage)`
  max-width: 100%;
  height: auto;
  max-height: 90vh;
  
  @media (max-width: 768px) {
    max-height: none;
    width: 95%;
    height: auto;
    
    .st0 {
      font-size: 24px;
    }
    
    .st1 {
      font-size: 150px;
    }
  }

  @media (max-width: 480px) {
    width: 98%;
    
    .st0 {
      font-size: 20px;
    }
    
    .st1 {
      font-size: 120px;
    }
  }
`;

const Sales = () => {
  return (
    <SalesContainer>
      <StyledSalesImage />
    </SalesContainer>
  );
};

export default Sales;