import React from 'react';
import StarryBackground from '../../StarryBackground/StarryBackground';
import './agreement.css';

const Agreement = () => {
  return (
    <div className="agreement-container">
      <StarryBackground />
      
      <div className="agreement-content">
        <header className="agreement-header">
          <h1>Согласие на обработку персональных данных</h1>
        </header>

        <main className="agreement-main">
          <section className="agreement-section">
            <p>
              Настоящим в соответствии с Федеральным законом от 27.07.2006 N 152-ФЗ "О персональных данных" 
              я принимаю полностью Политику в отношении обработки персональных данных и даю ИП Гаврилова 
              Татьяна Васильевна, (ОГРНИП 309774614101226) согласие на автоматизированную, а также без 
              использования средств автоматизации обработку путем сбора, систематизации, накопления, 
              хранения, уточнения и удаления, а также путем передачи (предоставления) третьим лицам, 
              привлеченным ИП Гаврилова Татьяна Васильевна, в том числе трансграничную передачу, моих 
              персональных данных, а именно:
            </p>

            <ul className="data-list">
              <li>фамилия, имя, отчество;</li>
              <li>адрес электронной почты;</li>
              <li>ID в социальной сети telegram;</li>
            </ul>

            <p>
              указанных при использовании сервиса для обучения нейросетям @how2ai_bot{' '}
              <a href="https://t.me/how2ai_bot" target="_blank" rel="noopener noreferrer" style={{color: '#fff'}}>
                https://t.me/how2ai_bot
              </a>
              {' '}или переданных ИП Гаврилова Татьяна Васильевна по электронной 
              почте и/или лично, для достижения следующих целей:
            </p>

            <ul className="goals-list">
              <li>информирование меня посредством отправки сообщений в Telegram;</li>
              <li>предоставление мне доступа к программам how2ai бот и его сервисным возможностям;</li>
              <li>анализ предоставленных мною данных, формирование статистики.</li>
            </ul>

            <p>
              Согласие действует до достижения целей обработки персональных данных или направлению мною 
              отказа от обработки персональных данных по адресу:{' '}
              <a href="https://t.me/dan4eck" target="_blank" rel="noopener noreferrer" style={{color: '#fff'}}>
                https://t.me/dan4eck
              </a>
              .
            </p>
          </section>
        </main>
      </div>
    </div>
  );
};

export default Agreement;
