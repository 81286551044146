import React from 'react';
import { Link } from 'react-router-dom';
import './PaymentFail.css';

const PaymentFail = () => {
  return (
    <div className="result-container">
      <main className="result-content">
        <section className="result-section">
          <h2>Ошибка Оплаты</h2>
          <p>К сожалению, ваш платеж не удалось обработать. Пожалуйста, попробуйте еще раз.</p>
          <Link to="/community" className="return-button">
            Вернуться в сообщество
          </Link>
        </section>
      </main>
    </div>
  );
};

export default PaymentFail;