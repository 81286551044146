import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import StarryBackground from '../StarryBackground/StarryBackground';
import CatSticker from '../CatSticker/CatSticker';
import './Home.css';

const Home = () => {
  // Generate random stickers for the Home page
  const catStickers = useMemo(() => {
    return Array.from({ length: 5 }, (_, index) => ({
      id: index,
      style: {
        position: 'absolute',
        top: `${Math.random() * 90}%`,
        left: `${Math.random() * 90}%`,
        transform: `rotate(${Math.random() * 360}deg) scale(${0.8 + Math.random() * 0.4})`,
      }
    }));
  }, []);

  return (
    <div className="homepage-container">
      <StarryBackground />
      
      {/* Add cat stickers */}
      <div className="stickers-container">
        {catStickers.map(cat => (
          <CatSticker key={cat.id} style={cat.style} />
        ))}
      </div>

      <div className="homepage-content">
        <header className="home-header">
          <h1 className="project-title">#продсовет</h1>
          <p className="project-description">
            Мы изучаем ИИ и технологии и рассказываем, как улучшить свою жизнь при помощи передовых инструментов!
          </p>
        </header>

        <nav className="navigation-grid">
          <Link to="/community" className="nav-item">
            <div className="nav-box">
              <span className="nav-text">Сообщество</span>
            </div>
          </Link>

          <div className="nav-item">
            <div className="nav-box">
              <span className="nav-text">Курсы</span>
            </div>
          </div>

          <div className="nav-item">
            <div className="nav-box">
              <span className="nav-text">Промптграмминг!</span>
            </div>
          </div>

          <div className="nav-item">
            <div className="nav-box">
              <span className="nav-text">Скидки и акции</span>
            </div>
          </div>

          <div className="nav-item">
            <div className="nav-box">
              <span className="nav-text">О проекте</span>
            </div>
          </div>
        </nav>

        <footer className="home-footer">
          <div className="footer-links">
            <a 
              href="https://www.youtube.com/@ProdAdvice/featured" 
              className="footer-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="emoji">▶️</span>
              <span>наш ютуб</span>
            </a>
            <a 
              href="https://t.me/How2AI" 
              className="footer-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="emoji">✈️</span>
              <span>наш тг</span>
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Home;