import React from 'react';
import './StarryBackground.css';

const StarryBackground = ({ className = '' }) => {
  return (
    <div className={`starry-background ${className}`}>
      <div className="stars-container">
        {/* The animation layers are handled by CSS pseudo-elements */}
      </div>
    </div>
  );
};

export default StarryBackground; 