import React from 'react';
import './CatSticker.css';
import catSticker from '../../assets/cat_sticker.png';

const CatSticker = ({ style }) => {
  return (
    <img 
      src={catSticker} 
      alt="" 
      className="cat-sticker" 
      style={style}
    />
  );
};

export default CatSticker; 