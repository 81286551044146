import React, { useMemo } from 'react';
import StarryBackground from '../StarryBackground/StarryBackground';
import CatSticker from '../CatSticker/CatSticker';
import './Community.css';

const Community = () => {
  // Generate random stickers with absolute positioning
  const catStickers = useMemo(() => {
    return Array.from({ length: 5 }, (_, index) => ({
      id: index,
      style: {
        position: 'absolute', // Changed from 'fixed' to 'absolute'
        top: `${Math.random() * 90}%`,
        left: `${Math.random() * 90}%`,
        transform: `rotate(${Math.random() * 360}deg) scale(${0.8 + Math.random() * 0.4})`,
      }
    }));
  }, []);

  const handlePaymentClick = () => {
    window.location.href = "https://auth.robokassa.ru/Merchant/Index.aspx?MerchantLogin=prodadvice_courses_1&OutSum=1400&InvoiceID=0&Description=%D0%B4%D0%BE%D0%BD%D0%B0%D1%82&SignatureValue=d1fd2153ff00d198c5f4da327e2b74ef";
  };

  return (
    <div className="community-container">
      <StarryBackground />
      
      {/* Stickers container with relative positioning */}
      <div className="stickers-container">
        {catStickers.map(cat => (
          <CatSticker key={cat.id} style={cat.style} />
        ))}
      </div>

      <div className="community-content">
        <header className="community-header">
          <h1>Сообщество Продсовета</h1>
        </header>

        <main>
          <section className="intro-section">
            <p>
              Cообщество ПродСовет – это сообщество нейроэнтузиастов. Мы изучаем, как внедрять ИИ в рабочие и бизнес-процессы, 
              а также создавать новые проекты и стартапы при помощи нейросетей.
            </p>
          </section>

          <section className="subscription-section">
            <h2>Что входит в подписку?</h2>
            <ul className="benefits-list">
              <li>Доступ к чату со всеми участниками, Дэниалом и Игоряном</li>
              <li>Доступ к закрытой базе эфиров, которые помогут вам в освоении ИИ-интструментов</li>
              <li>Доступ к базе знаний сообщества. Формируется участниками для участников</li>
              <li>Возможность принять участие в новых эфирах и предложить свои, интересующие вас темы и идеи</li>
              <li>Возможность рассказать о своем бизнесе/ проекте и найти клиентов/ партнеров/сотрудников</li>
              <li>Возможность обрасти новыми связями и полезными контактами</li>
              <li>Реклама вашего бизнеса/бренда на наших ресурсах (ютуб / тг-канал)</li>
            </ul>
          </section>

          <div className="price-section">
            <h3 className="price-amount">1400 ₽</h3>
            <p className="price-period">за месяц</p>
          </div>

          <button 
            className="payment-button"
            onClick={handlePaymentClick}
          >
            Оплатить Подписку
          </button>
        </main>
      </div>

      <footer className="community-footer">
        <div className="footer-links">
          <a 
            href="https://docs.google.com/document/d/1niAWJcw7jqTgFvUh0U9OziEUHJ56k2-lYmSoahxqOoY/edit?usp=sharing" 
            className="footer-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="emoji">📄</span>
            <span>оферта</span>
          </a>
          <a 
            href="https://www.youtube.com/@ProdAdvice/featured" 
            className="footer-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="emoji">▶️</span>
            <span>наш ютуб</span>
          </a>
          <a 
            href="https://t.me/How2AI" 
            className="footer-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="emoji">✈️</span>
            <span>наш тг</span>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Community;