import React from 'react';
import StarryBackground from '../../StarryBackground/StarryBackground';
import './oferta.css';

const Oferta = () => {
  return (
    <div className="oferta-container">
      <StarryBackground />
      
      <div className="oferta-content">
        <header className="oferta-header">
          <h1>ПУБЛИЧНАЯ ОФЕРТА</h1>
          <h2>о заключении договора об оказании услуг</h2>
        </header>

        <main className="oferta-main">
          <section className="oferta-section">
            <h3>Общие положения</h3>
            <p>
              В настоящей Публичной оферте содержатся условия заключения Договора об оказании услуг (далее по тексту - «Договор об оказании услуг» и/или «Договор»). Настоящей офертой признается предложение, адресованное одному или нескольким конкретным лицам, которое достаточно определенно и выражает намерение лица, сделавшего предложение, считать себя заключившим Договор с адресатом, которым будет принято предложение.
            </p>
            <p>
              Совершение указанных в настоящей Оферте действий является подтверждением согласия обеих Сторон заключить Договор об оказании услуг на условиях, в порядке и объеме, изложенных в настоящей Оферте.
            </p>
            <p>
              Нижеизложенный текст Публичной оферты является официальным публичным предложением Исполнителя, адресованный заинтересованному кругу лиц заключить Договор об оказании услуг в соответствии с положениями пункта 2 статьи 437 Гражданского кодекса РФ.
            </p>
            <p>
              Договор об оказании услуг считается заключенным и приобретает силу с момента совершения Сторонами действий, предусмотренных в настоящей Оферте, и, означающих безоговорочное, а также полное принятие всех условий настоящей Оферты без каких-либо изъятий или ограничений на условиях присоединения.
            </p>

            <h3>Термины и определения:</h3>
            <p>
              <strong>Договор</strong> – текст настоящей Оферты с Приложениями, являющимися неотъемлемой частью настоящей Оферты, акцептованный Заказчиком путем совершения конклюдентных действий, предусмотренных настоящей Офертой.
            </p>
            <p>
              <strong>Конклюдентные действия</strong> — это поведение, которое выражает согласие с предложением контрагента заключить, изменить или расторгнуть договор. Действия состоят в полном или частичном выполнении условий, которые предложил контрагент.
            </p>
            <p>
              <strong>Сайт Исполнителя в сети «Интернет»</strong> – совокупность программ для электронных вычислительных машин и иной информации, содержащейся в информационной системе, доступ к которой обеспечивается посредством сети «Интернет» по доменному имени и сетевому адресу: https://t.me/how2ai_bot
            </p>
            <p>
              <strong>Стороны Договора (Стороны)</strong> – Исполнитель и Заказчик.
            </p>
            <p>
              <strong>Услуга</strong> – услуга, оказываемая Исполнителем Заказчику в порядке и на условиях, установленных настоящей Офертой.
            </p>

            <h3>Предмет Договора</h3>
            <p>
              Исполнитель обязуется оказать Заказчику Услуги, а Заказчик обязуется оплатить их в размере, порядке и сроки, установленные настоящим Договором.
            </p>
            <p>
              Наименование, количество, порядок и иные условия оказания Услуг определяются на основании сведений Исполнителя при оформлении заявки Заказчиком, либо устанавливаются на сайте Исполнителя в сети «Интернет» https://t.me/how2ai_bot
            </p>
            <p>
              Исполнитель оказывает Услуги по настоящему Договору лично, либо с привлечением третьих лиц, при этом за действия третьих лиц Исполнитель отвечает перед Заказчиком как за свои собственные.
            </p>
            <p>
              Договор заключается путем акцепта настоящей Оферты через совершение конклюдентных действий, выраженных в:
            </p>
            <ul>
              <li>действиях, связанных с регистрацией учетной записи на Сайте Исполнителя в сети «Интернет» при наличии необходимости регистрации учетной записи;</li>
              <li>оформлении и направлении Заказчиком заявки в адрес Исполнителя для оказания Услуг;</li>
              <li>действиях, связанных с оплатой Услуг Заказчиком;</li>
              <li>действиях, связанных с оказанием Услуг Исполнителем.</li>
            </ul>
            <p>
              Данный перечень неисчерпывающий, могут быть и другие действия, которые ясно выражают намерение лица принять предложение контрагента.
            </p>

            <h3>Права и обязанности Сторон</h3>
            <h4>Права и обязанности Исполнителя:</h4>
            <p>
              Исполнитель обязуется оказать Услуги в соответствии с положениями настоящего Договора, в сроки и объеме, указанные в настоящем Договоре и (или) в порядке, указанном на Сайте Исполнителя.
            </p>
            <p>
              Исполнитель обязуется предоставлять Заказчику доступ к разделам Сайта, необходимым для получения информации, согласно пункту 2.1. Договора.
            </p>
            <p>
              Исполнитель несет ответственность за хранение и обработку персональных данных Заказчика, обеспечивает сохранение конфиденциальности этих данных и использует их исключительно для качественного оказания Услуг Заказчику.
            </p>
            <p>
              Исполнитель оставляет за собой право изменять сроки (период) оказания Услуг и условия настоящей Оферты в одностороннем порядке без предварительного уведомления Заказчика, публикуя указанные изменения на Сайте Исполнителя в сети «Интернет».
            </p>
            <p>
              При этом новые / измененные условия, указываемые на Сайте, действуют только в отношении вновь заключаемых Договоров.
            </p>


            <h4>Права и обязанности Заказчика:</h4>
            <p>
              Заказчик обязан предоставлять достоверную информацию о себе при получении соответствующих Услуг.
            </p>
            <p>
              Заказчик обязуется не воспроизводить, не повторять, не копировать, не продавать, а также не использовать в каких бы то ни было целях информацию и материалы, ставшие ему доступными в связи с оказанием Услуг, за исключением личного использования непосредственно самим Заказчиком без предоставления в какой-либо форме доступа каким-либо третьим лицам.
            </p>
            <p>
              Заказчик обязуется принять Услуги, оказанные Исполнителем;
            </p>
            <p>
              Заказчик вправе потребовать от Исполнителя вернуть денежные средства за неоказанные услуги, некачественно оказанные услуги, услуги, оказанные с нарушением сроков оказания, а также, если Заказчик решил отказаться от услуг по причинам, не связанным с нарушением обязательств со стороны Исполнителя, исключительно по основаниям, предусмотренным действующим законодательством Российской Федерации.
            </p>
            <p>
              Заказчик гарантирует, что все условия Договора ему понятны; Заказчик принимает условия без оговорок, а также в полном объеме.
            </p>

            <h3>Цена и порядок расчетов</h3>
            <p>
              Стоимость услуг Исполнителя, оказываемых Заказчиком и порядок их оплаты, определяются на основании сведений Исполнителя при оформлении заявки Заказчиком либо устанавливаются на Сайте Исполнителя в сети «Интернет»: https://t.me/how2ai_bot
            </p>
            <p>
              Все расчеты по Договору производятся в безналичном порядке.
            </p>

            <h3>Конфиденциальность и безопасность</h3>
            <p>
              При реализации настоящего Договора Стороны обеспечивают конфиденциальность и безопасность персональных данных в соответствии с актуальной редакцией ФЗ от 27.07.2006 г. № 152-ФЗ «О персональных данных» и ФЗ от 27.07.2006 г. № 149-ФЗ «Об информации, информационных технологиях и о защите информации».
            </p>
            <p>
              Стороны обязуются сохранять конфиденциальность информации, полученной в ходе исполнения настоящего Договора, и принять все возможные меры, чтобы предохранить полученную информацию от разглашения.
            </p>
            <p>
              Под конфиденциальной информацией понимается любая информация, передаваемая Исполнителем и Заказчиком в процессе реализации Договора и подлежащая защите, исключения указаны ниже.
            </p>
            <p>
              Такая информация может содержаться в предоставляемых Исполнителю локальных нормативных актах, договорах, письмах, отчетах, аналитических материалах, результатах исследований, схемах, графиках, спецификациях и других документах, оформленных как на бумажных, так и на электронных носителях.
            </p>

            <h3>Форс-мажор</h3>
            <p>
              Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение обязательств по Договору, если надлежащее исполнение оказалось невозможным вследствие непреодолимой силы, то есть чрезвычайных и непредотвратимых при данных условиях обстоятельств, под которыми понимаются: запретные действия властей, эпидемии, блокада, эмбарго, землетрясения, наводнения, пожары или другие стихийные бедствия.
            </p>
            <p>
              В случае наступления этих обстоятельств Сторона обязана в течение 30 (Тридцати) рабочих дней уведомить об этом другую Сторону.
            </p>
            <p>
              Документ, выданный уполномоченным государственным органом, является достаточным подтверждением наличия и продолжительности действия непреодолимой силы.
            </p>
            <p>
              Если обстоятельства непреодолимой силы продолжают действовать более 60 (Шестидесяти) рабочих дней, то каждая Сторона вправе отказаться от настоящего Договора в одностороннем порядке.
            </p>

            <h3>Ответственность Сторон</h3>
            <p>
              В случае неисполнения и/или ненадлежащего исполнения своих обязательств по Договору, Стороны несут ответственность в соответствии с условиями настоящей Оферты.
            </p>
            <p>
              Исполнитель не несет ответственности за неисполнение и/или ненадлежащее исполнение обязательств по Договору, если такое неисполнение и/или ненадлежащее исполнение произошло по вине Заказчика.
            </p>
            <p>
              Сторона, не исполнившая или ненадлежащим образом исполнившая обязательства по Договору, обязана возместить другой Стороне причиненные такими нарушениями убытки.
            </p>

            <h3>Срок действия настоящей Оферты</h3>
            <p>
              Оферта вступает в силу с момента размещения на Сайте Исполнителя и действует до момента её отзыва Исполнителем.
            </p>
            <p>
              Исполнитель оставляет за собой право внести изменения в условия Оферты и/или отозвать Оферту в любой момент по своему усмотрению. Сведения об изменении или отзыве Оферты доводятся до Заказчика по выбору Исполнителя посредством размещения на сайте Исполнителя в сети «Интернет», в Личном кабинете Заказчика, либо путем направления соответствующего уведомления на электронный или почтовый адрес, указанный Заказчиком при заключении Договора или в ходе его исполнения.
            </p>
            <p>
              Договор вступает в силу с момента Акцепта условий Оферты Заказчиком и действует до полного исполнения Сторонами обязательств по Договору.
            </p>
            <p>
              Изменения, внесенные Исполнителем в Договор и опубликованные на сайте в форме актуализированной Оферты, считаются принятыми Заказчиком в полном объеме.
            </p>


            <h3>Дополнительные условия</h3>
            <p>
              Договор, его заключение и исполнение регулируется действующим законодательством Российской Федерации. Все вопросы, не урегулированные настоящей Офертой или урегулированные не полностью, регулируются в соответствии с материальным правом Российской Федерации.
            </p>
            <p>
              В случае возникновения спора, который может возникнуть между Сторонами в ходе исполнения ими своих обязательств по Договору, заключенному на условиях настоящей Оферты, Стороны обязаны урегулировать спор мирным путем до начала судебного разбирательства.
            </p>
            <p>
              Судебное разбирательство осуществляется в соответствии с законодательством Российской Федерации.
            </p>
            <p>
              Споры или разногласия, по которым Стороны не достигли договоренности, подлежат разрешению в соответствии с законодательством РФ. Досудебный порядок урегулирования спора является обязательным.
            </p>
            <p>
              В качестве языка Договора, заключаемого на условиях настоящей Оферты, а также языка, используемого при любом взаимодействии Сторон (включая ведение переписки, предоставление требований / уведомлений / разъяснений, предоставление документов и т. д.), Стороны определили русский язык.
            </p>
            <p>
              Все документы, подлежащие предоставлению в соответствии с условиями настоящей Оферты, должны быть составлены на русском языке либо иметь перевод на русский язык, удостоверенный в установленном порядке.
            </p>
            <p>
              Бездействие одной из Сторон в случае нарушения условий настоящей Оферты не лишает права заинтересованной Стороны осуществлять защиту своих интересов позднее, а также не означает отказа от своих прав в случае совершения одной из Сторон подобных либо сходных нарушений в будущем.
            </p>
            <p>
              Если на Сайте Исполнителя в сети «Интернет» есть ссылки на другие веб-сайты и материалы третьих лиц, такие ссылки размещены исключительно в целях информирования, и Исполнитель не имеет контроля в отношении содержания таких сайтов или материалов. Исполнитель не несет ответственность за любые убытки или ущерб, которые могут возникнуть в результате использования таких ссылок.
            </p>
          </section>
        </main>

        <footer className="oferta-footer">
          <h3>Реквизиты Исполнителя</h3>
          <p>Полное наименование: Гаврилова Татьяна Васильевна</p>
          <p>ИНН: 771815234704</p>
          <p>ОГРН/ОГРНИП: 309774614101226</p>
          <p>Контактный телефон: +7 916 215-98-36</p>
          <p>Контактный e-mail: doonoff@gmail.com</p>
        </footer>
      </div>
    </div>
  );
};

export default Oferta;
