import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/Home/Home';
import PaymentSuccess from './components/PaymentSuccess/PaymentSuccess';
import PaymentFail from './components/PaymentFail/PaymentFail';
import Community from './components/Community/Community';
import Sales from './components/Sales/Sales';
import Oferta from './components/utility_pages/oferta/oferta';
import Agreement from './components/utility_pages/agreement/agreement';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/success" element={<PaymentSuccess />} />
          <Route path="/fail" element={<PaymentFail />} />
          <Route path="/community" element={<Community />} />
          <Route path="/sales" element={<Sales />} />
          <Route path="/oferta" element={<Oferta />} />
          <Route path="/agreement" element={<Agreement />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;